import React, { useRef, useEffect, useState } from "react";
import { CoLCat, CoLData } from "./data/CostofLivingCat";
import * as d3 from "d3";

//export default Chart;

export default function Chart({ data }) {
  const svgRef = useRef();
  let count = 1;

  useEffect(() => {
    const width= 900,height= 800;
    const margin = {top:20,bottom:20,left:20,right:20};
    const svg = d3.select(svgRef.current);
    let tooltip = d3.select("body").append("div").attr("class", "tooltip").style("opacity", "0");

    svg.attr("width", width).attr("height", height);
    const itemArray = CoLCat.CoL.map((x) => x.Item);
    let scaledArray = [],
      categoryArray = [0];
    const len = itemArray.length;
    let cat = 1;
    for (let i = 0; i < len; i++) {
      const rowStart = (i * 580) / (len - 1);
      if (CoLCat.CoL[i]["Cat ID"] !== cat) {
        categoryArray.push(rowStart);
        cat++;
      }
      scaledArray.push(rowStart);
    }

    let yScale = d3.scaleOrdinal().domain(itemArray).range(scaledArray);
    let yAxis = d3.axisLeft(yScale).tickSize(640).tickPadding(10);
    let color = d3
      .scaleLinear()
      .domain(categoryArray.map((d) => d))
      .range(d3.schemePastel1);

    let costArrayHi = CoLData[data].map((x) => x.High);
    let costArrayLo = CoLData[data].map((x) => x.Low);

    let xScale = d3
      .scaleLinear()
      .domain([d3.min(costArrayLo), d3.max(costArrayHi)])
      .range([0, 640]);
    let xAxis = d3.axisBottom(xScale).ticks(10).tickFormat(d3.format("($.0f"));

      const svgOuter = svg.append('g');
      svgOuter
        .selectAll("rect")
        .data(categoryArray)
        .enter()
        .append("rect")
        .attr("x", 0)
        .attr("y", (d) => d + 10)
        .attr("width", 200)
        .attr("height", (d, i) => {
          if (i < categoryArray.length - 1) return categoryArray[i + 1] - d;
          else return 600 - d;
        })
        .style("fill", (d) => color(d))
        .style("opacity", "0.4");
    
        svg
        .append("g")
        .attr("class", "yAxis")
        .attr("transform", "translate(840,20)")
        .call(yAxis);

      svg
        .append("g")
        .attr("class", "xAxis")
        .attr("transform", "translate(200,625)")
        .call(xAxis);

        const svgInner = svg.append('g').attr("transform", "translate(200,20)")
        
        svgInner.selectAll('.bands')
        .data(CoLData[data])
        .enter()
        .append('rect')
        .attr('y',d=>(yScale(d["Item ID"])-5))
        .attr('x',0)
        .attr('height',10)
        .attr('width',d=>(xScale(d.High)-xScale(d.Low)))
        .attr('class', 'bands');
        
        svgInner.selectAll('.bands')
        .transition()
        .duration(600)
        .attr('x',d=>xScale(d.Low));
        
        svgInner.selectAll('.colcircle')
        .data(CoLData[data])
        .enter()
        .append('circle')
        .attr('cy',d=>(yScale(d["Item ID"])))
        .attr('cx',0)
        .attr('r',4)
        .attr('class', 'colcircle')
        .on("mouseover",function (event,d,i){
            const catDetails = CoLCat.CoL.find(x=>d["Item ID"]===x["Item ID"]);
            const details = "<p>Category: "+catDetails.Category+"</p>"+
                            "<p>Item: <span class='strong'>"+catDetails.Item+"</span></p>"+
                            "<p>Average: <span class='strong'>$" + d.Average + "</span></b></p><p>High: $" + d.High+ "</p><p>Low: $"+d.Low+"</p>";
            tooltip.html(details);
            tooltip.style("top", (event.pageY)+"px");
            tooltip.style("left",((event.pageX)+10)+"px");
            tooltip.style("opacity", "0.8");
          })
        .on("mouseout",()=> tooltip.style("opacity", "0"));
        
        svgInner.selectAll('.colcircle')
        .transition()
        .duration(1000)
        .attr('cx',d=>xScale(d.Average));
      
     return () => svg.selectAll("*").remove();   
  }, [data]);

  return <div>
        <svg ref={svgRef}></svg>
    </div>;
}

import React, { useRef, useEffect, useState } from "react";

export default function Header() {

    return (
        <div className="header">
            <div className="logocontainer">{"{ DataViz }"}</div>
            <div className="menu">
                <ul className="nav">
                    <li>DataViz</li>
                    <li>FIRE</li>
                    <li>Misc</li>
                </ul>
            </div>
        <div></div>
    </div>
    )

}
export const CoLCat = {
    CoL: [
      {
        Category: "Restaurants",
        "Cat ID": 1,
        "Item ID": 1,
        Item: "Meal, Inexpensive Restaurant"
      },
      {
        Category: "Restaurants",
        "Cat ID": 1,
        "Item ID": 2,
        Item: "Meal for 2, Mid-range, Three-course"
      },
      {
        Category: "Restaurants",
        "Cat ID": 1,
        "Item ID": 3,
        Item: "McMeal McD (or Equ. Combo Meal)"
      },
      {
        Category: "Restaurants",
        "Cat ID": 1,
        "Item ID": 4,
        Item: "Domestic Beer (0.5l draught)"
      },
      {
        Category: "Restaurants",
        "Cat ID": 1,
        "Item ID": 5,
        Item: "Imported Beer (0.33l bottle)"
      },
      {
        Category: "Restaurants",
        "Cat ID": 1,
        "Item ID": 6,
        Item: "Cappuccino (regular)"
      },
      {
        Category: "Markets",
        "Cat ID": 2,
        "Item ID": 7,
        Item: "Monthly Groceries (Western food)"
      },
      {
        Category: "Markets",
        "Cat ID": 2,
        "Item ID": 8,
        Item: "Monthly Groceries (Asian food)"
      },
      {
        Category: "Transportation",
        "Cat ID": 3,
        "Item ID": 9,
        Item: "One-way Ticket (Local Transport)"
      },
      {
        Category: "Transportation",
        "Cat ID": 3,
        "Item ID": 10,
        Item: "Monthly Pass (Regular Price)"
      },
      {
        Category: "Transportation",
        "Cat ID": 3,
        "Item ID": 11,
        Item: "Taxi Start (Normal Tariff)"
      },
      {
        Category: "Transportation",
        "Cat ID": 3,
        "Item ID": 12,
        Item: "Taxi 1km (Normal Tariff)"
      },
      {
        Category: "Transportation",
        "Cat ID": 3,
        "Item ID": 13,
        Item: "Taxi 1hour Waiting (Normal Tariff)"
      },
      {
        Category: "Transportation",
        "Cat ID": 3,
        "Item ID": 14,
        Item: "Gasoline (1 liter)"
      },
      {
        Category: "Transportation",
        "Cat ID": 3,
        "Item ID": 15,
        Item: "Car Monthly (incl. insurance)"
      },
      {
        Category: "Utilities ",
        "Cat ID": 4,
        "Item ID": 16,
        Item: "Basic Utilities (Ele, Water, Garbage)"
      },
      {
        Category: "Utilities ",
        "Cat ID": 4,
        "Item ID": 17,
        Item: "1 Month Phone plans (SIM Only)"
      },
      {
        Category: "Utilities ",
        "Cat ID": 4,
        "Item ID": 18,
        Item: "Internet (60 Mbps+, Unlimited Data)"
      },
      {
        Category: "Sports And Leisure",
        "Cat ID": 5,
        "Item ID": 19,
        Item: "Fitness Club, Monthly Fee for 1 Adult"
      },
      {
        Category: "Sports And Leisure",
        "Cat ID": 5,
        "Item ID": 20,
        Item: "Tennis Court Rent (1hr)"
      },
      {
        Category: "Sports And Leisure",
        "Cat ID": 5,
        "Item ID": 21,
        Item: "Cinema, International Release, 1 Seat"
      },
      {
        Category: "Clothing And Shoes",
        "Cat ID": 6,
        "Item ID": 22,
        Item: "1 Pair of Jeans (Levis 501 Or Similar)"
      },
      {
        Category: "Clothing And Shoes",
        "Cat ID": 6,
        "Item ID": 23,
        Item: "1 Summer Dress (Zara, H&M, ...)"
      },
      {
        Category: "Clothing And Shoes",
        "Cat ID": 6,
        "Item ID": 24,
        Item: "Pair of Nike Shoes (Mid-Range)"
      },
      {
        Category: "Clothing And Shoes",
        "Cat ID": 6,
        "Item ID": 25,
        Item: "Pair of Men Leather Shoes"
      }
    ]
  };
  
  export const CoLData = {
    London: [
      {
        "Item ID": 1,
        Average: 15.5,
        Low: 10,
        High: 30
      },
      {
        "Item ID": 2,
        Average: 67,
        Low: 49.44,
        High: 100
      },
      {
        "Item ID": 3,
        Average: 7,
        Low: 6,
        High: 8
      },
      {
        "Item ID": 4,
        Average: 6,
        Low: 4,
        High: 7.05
      },
      {
        "Item ID": 5,
        Average: 5,
        Low: 4,
        High: 7.5
      },
      {
        "Item ID": 6,
        Average: 3.26,
        Low: 2.1,
        High: 5
      },
      {
        "Item ID": 7,
        Average: 218,
        Low: 150,
        High: 400
      },
      {
        "Item ID": 8,
        Average: 171,
        Low: 120,
        High: 350
      },
      {
        "Item ID": 9,
        Average: 2.8,
        Low: 1.79,
        High: 5
      },
      {
        "Item ID": 10,
        Average: 160,
        Low: 130,
        High: 250
      },
      {
        "Item ID": 11,
        Average: 4.8,
        Low: 3,
        High: 6
      },
      {
        "Item ID": 12,
        Average: 1.7,
        Low: 1.22,
        High: 2.2
      },
      {
        "Item ID": 13,
        Average: 28.8,
        Low: 18.6,
        High: 31.7
      },
      {
        "Item ID": 14,
        Average: 1.76,
        Low: 1.64,
        High: 1.95
      },
      {
        "Item ID": 15,
        Average: 450,
        Low: 300,
        High: 600
      },
      {
        "Item ID": 16,
        Average: 248.07,
        Low: 148.75,
        High: 453.33
      },
      {
        "Item ID": 17,
        Average: 15,
        Low: 8,
        High: 40
      },
      {
        "Item ID": 18,
        Average: 29.91,
        Low: 24,
        High: 40.23
      },
      {
        "Item ID": 19,
        Average: 44.55,
        Low: 22,
        High: 87.28
      },
      {
        "Item ID": 20,
        Average: 11.97,
        Low: 7,
        High: 15
      },
      {
        "Item ID": 21,
        Average: 13,
        Low: 9,
        High: 18
      },
      {
        "Item ID": 22,
        Average: 76.56,
        Low: 45,
        High: 100
      },
      {
        "Item ID": 23,
        Average: 34,
        Low: 20,
        High: 50
      },
      {
        "Item ID": 24,
        Average: 75.24,
        Low: 60,
        High: 100
      },
      {
        "Item ID": 25,
        Average: 94.66,
        Low: 50,
        High: 200
      }
    ],
    Lisbon: [
      {
        "Item ID": 1,
        Average: 10,
        Low: 5,
        High: 15
      },
      {
        "Item ID": 2,
        Average: 40,
        Low: 20,
        High: 50
      },
      {
        "Item ID": 3,
        Average: 7,
        Low: 6,
        High: 8
      },
      {
        "Item ID": 4,
        Average: 6,
        Low: 4,
        High: 7.05
      },
      {
        "Item ID": 5,
        Average: 5,
        Low: 4,
        High: 7.5
      },
      {
        "Item ID": 6,
        Average: 3.26,
        Low: 2.1,
        High: 5
      },
      {
        "Item ID": 7,
        Average: 150,
        Low: 100,
        High: 200
      },
      {
        "Item ID": 8,
        Average: 120,
        Low: 80,
        High: 200
      },
      {
        "Item ID": 9,
        Average: 2.8,
        Low: 1.79,
        High: 5
      },
      {
        "Item ID": 10,
        Average: 160,
        Low: 130,
        High: 250
      },
      {
        "Item ID": 11,
        Average: 4.8,
        Low: 3,
        High: 6
      },
      {
        "Item ID": 12,
        Average: 1.7,
        Low: 1.22,
        High: 2.2
      },
      {
        "Item ID": 13,
        Average: 28.8,
        Low: 18.6,
        High: 31.7
      },
      {
        "Item ID": 14,
        Average: 1.76,
        Low: 1.64,
        High: 1.95
      },
      {
        "Item ID": 15,
        Average: 450,
        Low: 300,
        High: 600
      },
      {
        "Item ID": 16,
        Average: 248.07,
        Low: 148.75,
        High: 453.33
      },
      {
        "Item ID": 17,
        Average: 15,
        Low: 8,
        High: 40
      },
      {
        "Item ID": 18,
        Average: 29.91,
        Low: 24,
        High: 40.23
      },
      {
        "Item ID": 19,
        Average: 44.55,
        Low: 22,
        High: 87.28
      },
      {
        "Item ID": 20,
        Average: 11.97,
        Low: 7,
        High: 15
      },
      {
        "Item ID": 21,
        Average: 13,
        Low: 9,
        High: 18
      },
      {
        "Item ID": 22,
        Average: 76.56,
        Low: 45,
        High: 100
      },
      {
        "Item ID": 23,
        Average: 34,
        Low: 20,
        High: 50
      },
      {
        "Item ID": 24,
        Average: 75.24,
        Low: 60,
        High: 100
      },
      {
        "Item ID": 25,
        Average: 94.66,
        Low: 50,
        High: 200
      }
    ]
  };
  

import React, { useState,useEffect } from "react";

export default function FDChart() {

let [output,setOutput] = useState("");  

const fetchData = async () => {
    console.log("calling BSE_DATA");
    const URL = "https://dataviz-5mk.pages.dev/app/bsedata";
    const response = await fetch(URL,{
      method: "GET",
      headers: {'Content-type': 'application/json'}
      });
    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
    setOutput(await response.json());
    return;
  }

useEffect(() => {
    const getData = async () => {
    await fetchData();
    console.log("output: " + output)
    };

    getData();

    return () => {setOutput("")};
},[]);

if(!output) return <div>loading...</div>;

return (
    <div>
        {output.map((lineitem)=>(
            <div>{lineitem.Date}</div>
        ))}
    </div>
    );
}
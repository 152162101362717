import './App.css';
import Chart from './Chart';
import React, { useState } from "react";
import Header from './header';
import FDChart from './FDChart';
//import { getAssetFromKV, NotFoundError, MethodNotAllowedError } from '@cloudflare/kv-asset-handler';
//import { Router } from 'itty-router';
//const router = Router();

function App() {
  let [city,setCity] = useState("London");
  
  const handleChange = (event) =>{
    setCity(event.target.value);
  }

  return (
    <div className="App">
      <Header/>
      <div className='selectionRow'>
        <div className='selectionBox'>
          <div>Select City</div>
          <select id="selOpt" value={city} onChange={handleChange}>
          <option value="London">London</option>
          <option value="Lisbon">Lisbon</option>
          </select>
        </div>
        <div class="CoLBox">
          <div>{city}: Cost of Living</div>
        </div>
      </div>
      <Chart data={city}/>
      <FDChart />
    </div>
  );
}

export default App;
